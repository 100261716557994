import React from "react";
import OverlayTrigger from "./OverlayTrigger";

import { TrashIcon, NoEntryIcon, PlusCircleIcon } from "@primer/octicons-react";
import { useLang } from "../context/lang";
import ConfirmPopover from "./ConfirmPopover";

export default ({ items, onClear }) => {
  const { t } = useLang();

  const handleDelete = () => {};

  return items.length > 0 ? (
    <div className="height-header w-100 bg-white shadow-sm flex-center-between px-20 mt-40">
      <div className="d-flex text-gray-500 text-16">
        <div
          style={{
            width: `${items.length.toString().length * 14}px`
          }}
        >
          {items.length}
        </div>
        {t("itemsSelected")}
      </div>
      <div className="d-flex">
        <div className="hover-elevate-200 rounded-pill cursor-pointer px-8">
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <ConfirmPopover
                onSubmit={handleDelete}
                onClose={() => document.getElementById("delete-button").click()}
                header={t("deleteConfirmation")}
                headerDetails={`(${items.length} ${t("selected")})`}
                text={t("undoAlert")}
              />
            }
          >
            <div id="delete-button">
              <TrashIcon size={17} />
              <span className="text-16 ml-8">{t("delete")}</span>
            </div>
          </OverlayTrigger>
        </div>
        <div className="ml-64 hover-elevate-200 rounded-pill cursor-pointer px-8">
          <NoEntryIcon size={17} />
          <span className="text-16 ml-8">{t("close")}</span>
        </div>
        <div className="ml-64 hover-elevate-200 rounded-pill cursor-pointer px-8">
          <PlusCircleIcon size={17} />
          <span className="text-16 ml-8">{t("open")}</span>
        </div>
      </div>
      <span
        className="text-danger text-14 font-weight-normal ml-16 cursor-pointer hover-underline"
        onClick={onClear}
      >
        {t("clearAll")}
      </span>
    </div>
  ) : null;
};

import Axios from "axios";

import { API_STUDENTS_BASE_URL } from "../config";
import { useAuthenticator } from "@aws-amplify/ui-react";

export const useStudentsApi = () => {
  const { user } = useAuthenticator();
  const userSession = user?.getSignInUserSession();
  const jwtAccessToken = userSession?.getAccessToken().getJwtToken();
  return Axios.create({
    baseURL: API_STUDENTS_BASE_URL,

    headers: {
      token: jwtAccessToken,
    },

    // headers: {
    //   "Content-Type": "application/json",
    //   ...(localStorage.getItem("tokenStudentsApi")
    //     ? { Authorization: ` Bearer ${localStorage.getItem("tokenStudentsApi")}` }
    //     : {}),
    // },
  });
};

import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import StatusPill from "../components/StatusPill";
import ConfirmPopover from "../components/ConfirmPopover";

import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";

import { useParams } from "react-router-dom";
import { useStudents } from "../context/students";
import { useLang } from "../context/lang";
import {
  PeopleIcon,
  NoteIcon,
  MailIcon,
  StarIcon,
  MortarBoardIcon,
  RocketIcon,
  MilestoneIcon,
  PaperAirplaneIcon,
  ClippyIcon,
  CalendarIcon,
  CommentDiscussionIcon,
  GlobeIcon,
  DatabaseIcon,
  ClockIcon,
  GraphIcon,
} from "@primer/octicons-react";
import { useHistory } from "react-router-dom";

import RejectForm from "../components/RejectForm";
import { ReactComponent as StarBlueIcon } from "../assets/starBlue.svg";
import WorkExperienceCard from "../components/WorkExperienceCard";

export default () => {
  const { id } = useParams();
  const {
    loadStudent,
    student,
    data,
    updateStudent,
    deleteStudent,
    setStudent,
  } = useStudents();
  const history = useHistory();

  const { t } = useLang();
  const [isRejectModalOpen, setRejectModalOpen] = React.useState(false);
  React.useEffect(() => {
    loadStudent(id);
    return () => setStudent(null);
  }, []);

  return student ? (
    <>
      <div className="w-100">
        <h2>{t("student")}</h2>
        <div className="flex-center-between align-items-start mt-40">
          <div className="d-flex flex-wrap">
            <img
              className="rounded mx-auto mb-10"
              src={student.avatarSrc}
              width="70"
            />
            <div className="ml-16">
              <div className="d-flex">
                <div className="font-weight-bold text-18 mr-10">
                  {student.name}
                </div>
                {/* <div className="text-gray-500 text-16">{student.bday}</div>
              <div className="d-flex mt-12">
                <BroadcastIcon className="mt-3" size={20} />
                <span className="text-16 ml-10">{student.phone}</span>
              </div> */}
                <StatusPill
                  chooseOptions={data.statusOptions}
                  onChoose={async (status) => {
                    await updateStudent(student.id, { status });
                    loadStudent(id);
                  }}
                  value={student.status}
                />
              </div>
              <div className="d-flex mt-12">
                <MailIcon className="mt-3" size={20} />
                <span className="text-16 ml-10">{student.email}</span>
              </div>
            </div>
          </div>
          {/* <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip id="tooltip-shortlist-bottom">
                {t("addToShortlist")}
              </Tooltip>
            }
          >
            <div className="d-flex">
              <StarIcon
                className="hover-elevate-200 cursor-pointer"
                size={20}
              />
            </div>
          </OverlayTrigger> */}
        </div>
        <div className="d-flex flex-wrap w-100 mt-32">
          <Button
            className="rounded-lg px-20 mt-20 mt-sm-0 ml-10"
            variant="primary"
            onClick={() => {
              window.location.href = `mailto:${student.email}`;
            }}
          >
            {t("sendEmail")}
          </Button>
          <OverlayTrigger
            trigger="click"
            placement="bottom-start"
            rootClose
            overlay={
              <ConfirmPopover
                onSubmit={async () => {
                  await deleteStudent(id);
                  history.push("/students");
                }}
                onClose={() => document.body.click()} //trigger outside click
                header={t("deleteConfirmation")}
                text={t("undoAlert")}
              />
            }
          >
            <Button
              className="rounded-lg mt-20 mt-sm-0 text-primary px-20 ml-10 "
              variant="secondary"
              id="clear-button"
            >
              {t("clearData")}
            </Button>
          </OverlayTrigger>
        </div>
        <Row className="mt-32">
          <Col xs={12} lg={6}>
            <div className="width-card bg-white shadow-sm rounded-lg px-20 py-18">
              <h4>{t("information")}</h4>
              <div className="d-flex mt-16">
                {student.sex && (
                  <>
                    <PeopleIcon className="mt-3 width-logo" size={22} />
                    <span className="text-gray-500 text-16 mx-10 width-title">
                      {t("sex")}
                    </span>
                  </>
                )}
                <span className="text-16 ml-10">{t(student.sex)}</span>
              </div>
              <div className="d-flex mt-16">
                {student.widgetLanguage && (
                  <>
                    <NoteIcon className="mt-2 width-logo" size={20} />
                    <span className="text-gray-500 text-16 mx-10 width-title">
                      {t("widgetLanguage")}
                    </span>
                    <span className="text-16 ml-10">
                      {t(student.widgetLanguage)}
                    </span>
                  </>
                )}
              </div>
              {/* <div className="d-flex mt-16">
                <MilestoneIcon className="mt-2" size={20} />
                <span className="text-16 ml-10">{student.education.major}</span>
              </div> */}
            </div>
            <div className="width-card bg-white shadow-sm rounded-lg px-20 py-18 mt-24">
              <h4>{t("preferences")}</h4>
              {/* <div className="d-flex mt-16">
                <PaperAirplaneIcon className="mt-2" size={22} />
                <span className="text-gray-500 text-16 mx-10 width-title">
                  {t("city")}
                </span>
                <span className="text-16 ml-10">{student.city}</span>
              </div> */}
              <div className="d-flex mt-16">
                {student.mode && (
                  <>
                    <CalendarIcon className="mt-2 width-logo" size={20} />
                    <span className="text-gray-500 text-16 mx-10 width-title">
                      {t("mode")}
                    </span>
                    <span className="text-16 ml-10">{t(student.mode)}</span>
                  </>
                )}
              </div>
              <div className="d-flex mt-16">
                {student.language && (
                  <>
                    <CommentDiscussionIcon
                      className="mt-2 width-logo"
                      size={20}
                    />
                    <span className="text-gray-500 text-16 mx-10 width-title">
                      {t("language")}
                    </span>
                    <span className="text-16 ml-10">{t(student.language)}</span>
                  </>
                )}
              </div>
              <div className="d-flex mt-16">
                {student.degree && (
                  <>
                    <ClippyIcon className="mt-2 width-logo" size={26} />
                    <span className="text-gray-500 text-16 mx-10 width-title">
                      {t("degree")}
                    </span>
                    <span className="text-16 ml-10">{t(student.degree)}</span>
                  </>
                )}
              </div>
            </div>

            <div className="width-card bg-white shadow-sm rounded-lg px-20 py-18 mt-24 mb-24">
              <h4>{t("personalityTraits")}</h4>
              {/* <div className="d-flex align-items-center mt-18 mb-16">
                <GraphIcon />
                <span className="ml-12">{`${student.maxScore} ${t(
                  "isTheMaximumScore"
                )}`}</span>
              </div> */}
              {student.skills?.map(({ key, value }) => (
                <div key={key} className="mt-8">
                  <div className="flex-center-between w-100">
                    <span className="text-gray-500 text-16">{t(key)}</span>
                    <span className="text-16">{`${String(value * 100).slice(
                      0,
                      2
                    )}%`}</span>
                  </div>
                  <ProgressBar
                    className="height-progress"
                    now={value * 100}
                    min={0}
                    max={100}
                  />
                </div>
              ))}
              {/* <div className="cursor-pointer hover-underline mt-20">
                <span>{t("meaningOfTheTest")}</span>
              </div> */}
            </div>
            {/* <div className="width-card d-flex bg-white shadow-sm rounded-lg px-20 py-18 mt-24">
              <div>
                <h4 className="mb-24">{t("toolsAndSkills")}</h4>
                {student.tools.map(({ key }) => (
                  <div className="mb-16">{key}</div>
                ))}
              </div>
              <div className="ml-20">
                <div className="text-gray-500 text-14 mb-24">
                  {t("advancedness")}
                </div>
                {student.tools.map(({ value }, i) => (
                  <div key={i} className="d-dlex mb-16">
                    {[...Array(parseInt(value))].map(i => (
                      <StarBlueIcon />
                    ))}
                  </div>
                ))}
              </div>
            </div> */}
          </Col>
          <Col xs={12} lg={6}>
            {student.majors?.map((major, i) => (
              <div className="width-card bg-white shadow-sm rounded-lg px-20 py-18 mb-24">
                {major.clicked ? (
                  <div className="d-flex mb-6">
                    <div className="text-white text-14 bg-blue my-4 rounded-pill px-8 py-2">
                      <span>{t("clicked")}</span>
                    </div>
                  </div>
                ) : null}
                <h4
                  className="cursor-pointer hover-underline"
                  onClick={() => window.open(major.link)}
                >
                  <span>{major.specialization}</span>
                </h4>
                <span className="text-gray-500 text-16">
                  {major.major_name}
                </span>
                <div className="d-flex mt-16">
                  <CalendarIcon className="mt-2" size={22} />
                  <span className="text-gray-500 text-16 mx-10 width-title">
                    {t("mode")}
                  </span>

                  <span className="text-16 ml-10">{t(major.mode)}</span>
                </div>
                <div className="d-flex mt-16">
                  <CommentDiscussionIcon className="mt-2" size={20} />
                  <span className="text-gray-500 text-16 mx-10 width-title">
                    {t("language")}
                  </span>

                  <span className="text-16 ml-10">{t(major.language)}</span>
                </div>
                <div className="d-flex mt-16">
                  <CommentDiscussionIcon className="mt-2" size={20} />
                  <span className="text-gray-500 text-16 mx-10 width-title">
                    {t("fit")}
                  </span>
                  <span className="text-16 ml-10">{`${String(
                    Math.ceil(major.fit * 100)
                  )}%`}</span>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </div>
      <RejectForm
        isOpen={isRejectModalOpen}
        onClose={() => setRejectModalOpen(false)}
        onSubmit={() => setRejectModalOpen(false)}
      />
    </>
  ) : null;
};

import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { PlusCircleIcon, DiffRemovedIcon } from "@primer/octicons-react";
import ReactTags from "react-tag-autocomplete";
import "../styles/reactTags/reactTags.css";
import reactTagClassNames from "../styles/reactTags/classNames";

import { Range, getTrackBackground } from "react-range";

import ConfirmPopover from "../components/ConfirmPopover";
import FormInput from "../components/FormInput";
import { useAuth } from "../context/authBasic";
import { useLang } from "../context/lang";
import { useMajors } from "../context/majors";
import { useLocation } from "react-router-dom";

export default () => {
  const [validated, setValidated] = React.useState(false);
  const formRef = React.useRef(null);

  const { pathname } = useLocation();
  const { t } = useLang();
  const { user } = useAuth();
  const { updateMajor, form, setForm, resetForm, initForm } = useMajors();
  const requiredFields = Object.keys(initForm); //all fields

  const validate = () => {
    return requiredFields
      .map((field) => {
        if (!form[field]) {
          return false;
        } else {
          return true;
        }
      })
      .every((v) => v);
  };

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (validate()) {
      updateMajor(form);
    }
    setValidated(true);
  };

  const handleClear = () => {
    resetForm();
  };

  const renderChooseButton = (options, key) => {
    return options.map((b) => (
      <Button
        key={b}
        variant={b === form[key] ? "primary" : "secondary"}
        onClick={() => setForm((prev) => ({ ...prev, [key]: b }))}
        className="mr-8"
      >
        {t(b)}
      </Button>
    ));
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSave}
      ref={formRef}
      className="w-100"
    >
      <h2 className="mb-40">{t(pathname.split("/")[1])}</h2>
      <div className="width-create-Major rounded-lg p-16 bg-white shadow-sm">
        <div className="text-18 mb-23 font-weight-bold">
          {`${t("majorInfo")}`}
        </div>
        {["majorName", "majorSpecialization"].map((name) => (
          <FormInput
            label={t(`${name}`)}
            value={form[name]}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, [name]: e.target.value }))
            }
            required={requiredFields.includes(name)}
          />
        ))}
        <FormInput
          label={t("priceEuro")}
          value={form.priceEuro.year}
          onChange={(e) =>
            setForm((prev) => ({
              ...prev,
              priceEuro: { ...prev.priceEuro, year: e.target.value },
            }))
          }
          required={requiredFields.includes("priceEuro")}
        />
        <FormInput
          label={t("priceNonEuro")}
          value={form.priceNonEuro.year}
          onChange={(e) =>
            setForm((prev) => ({
              ...prev,
              priceNonEuro: { ...prev.priceNonEuro, year: e.target.value },
            }))
          }
          required={requiredFields.includes("priceNonEuro")}
        />
        <div className="w-100 d-flex flex-wrap mt-20">
          <div className="flex-center-between w-100 text-muted text-16 mb-8">
            {t("mode")}
          </div>
          <div className="d-flex w-100">
            <div className="mr-20">
              {renderChooseButton(["fulltime", "parttime"], "mode")}
            </div>
          </div>
        </div>
        <div className="w-100 d-flex flex-wrap mt-20">
          <div className="flex-center-between w-100 text-muted text-16 mb-8">
            {t("language")}
          </div>
          <div className="d-flex w-100">
            <div className="mr-20">
              {renderChooseButton(["PL", "EN"], "language")}
            </div>
          </div>
        </div>
        <div className="w-100 d-flex flex-wrap mt-20 mb-20">
          <div className="flex-center-between w-100 text-muted text-16 mb-8">
            {t("degree")}
          </div>
          <div className="d-flex w-100">
            <div className="mr-20">
              {renderChooseButton(["bachelor", "master"], "degree")}
            </div>
          </div>
        </div>
        <FormInput
          label={t("link")}
          value={form["link"]}
          onChange={(e) =>
            setForm((prev) => ({ ...prev, link: e.target.value }))
          }
          required={requiredFields.includes("link")}
        />{" "}
        <div className="width-create-Major mt-30 flex-center-between">
          {pathname.includes("createMajor") && (
            <OverlayTrigger
              trigger="click"
              placement="top-start"
              overlay={
                <ConfirmPopover
                  onSubmit={handleClear}
                  onClose={() =>
                    document.getElementById("clear-button").click()
                  }
                  header={t("clearConfirmation")}
                  submitText={t("clear")}
                />
              }
            >
              <Button
                id="clear-button"
                className="rounded-lg text-white"
                variant="danger"
              >
                {t("clear")}
              </Button>
            </OverlayTrigger>
          )}
          {/* <Button
              className="rounded-lg text-primary mr-8"
              variant="secondary"
              onClick={handleSave}
            >
              {t("save")}
            </Button> */}
          <Button
            className="rounded-lg mr-32"
            variant="primary"
            onClick={handleSave}
          >
            {t("publish")}
          </Button>
        </div>
      </div>
    </Form>
  );
};

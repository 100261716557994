import React from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";

import { Authenticator } from "@aws-amplify/ui-react";

import Protected from "./components/Protected";
// import Login from "./components/LoginBasic";
import Header from "./components/Header";
import Menu from "./components/Menu";
import Students from "./containers/Students";
import Student from "./containers/Student";

import Majors from "./containers/Majors";
import CreateMajor from "./containers/CreateMajor";
// import Settings from "./containers/Settings";
import Export from "./containers/Export";

// import AuthProvider from "./context/authBasic";
import StudentsProvider from "./context/students";
import LangProvider from "./context/lang";
import MajorsProvider from "./context/majors";
import ModalProvider from "./context/modal";
import SearchProvider from "./context/search";

import translation from "./config/translation";
import "./styles/app.css";
import "./styles/login.css";

export default () => {
  return (
    <Router>
      <Authenticator.Provider>
        <LangProvider translation={translation}>
          <ModalProvider>
            {/* <AuthProvider> */}
            <Protected>
              <SearchProvider>
                <StudentsProvider>
                  <MajorsProvider>
                    <Switch>
                      {/* <Route path="/login" component={Login} /> */}
                      <div className="d-flex flex-column-mobile">
                        <Menu />
                        <div
                          id="app-body"
                          className="width-content mx-auto px-10 px-sm-5 overflow-y-scroll"
                        >
                          <Header />
                          <Route
                            path="/"
                            exact
                            component={() => <Redirect to="/students" />}
                          />
                          <Route path="/students/:id" component={Student} />
                          <Route exact path="/students" component={Students} />
                          <Route exact path="/majors" component={Majors} />
                          <Route
                            exact
                            path="/createMajor"
                            component={CreateMajor}
                          />
                          <Route
                            exact
                            path="/editMajor"
                            component={CreateMajor}
                          />
                          {/* <Route exact path="/settings" component={Settings} /> */}
                          <Route exact path="/export" component={Export} />
                        </div>
                      </div>
                    </Switch>
                  </MajorsProvider>
                </StudentsProvider>
              </SearchProvider>
            </Protected>

            {/* </AuthProvider> */}
          </ModalProvider>
        </LangProvider>
      </Authenticator.Provider>
    </Router>
  );
};

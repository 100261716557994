import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import * as XLSX from "xlsx";
import { XIcon } from "@primer/octicons-react";

import { useLang } from "../context/lang";
import FormInput from "../components/FormInput";
import { ReactComponent as ExportIcon } from "../assets/export.svg";
import {
  useExportStudents,
  convertToCSV,
  deflate,
} from "../hooks/useExportStudents";

const ensureSameKeys = (data: any[]) => {
  // Determine the complete set of keys
  const allKeys = new Set<string>();
  data.forEach((item) => {
    Object.keys(item).forEach((key) => allKeys.add(key));
  });

  // Add missing keys to each object
  return data.map((item) => {
    const newItem = { ...item };
    allKeys.forEach((key) => {
      if (!(key in newItem)) {
        newItem[key] = ""; // or any default value
      }
    });
    return newItem;
  });
};

export default ({ onClose }: any) => {
  const { t } = useLang();

  const { exportStudents } = useExportStudents();

  const [loadingExport, setLoadingExport] = React.useState(false);
  const [stoppingExport, setStoppingExport] = React.useState(false);

  const [form, setForm] = React.useState<{
    fromDate: string;
    toDate: string;
    format: "json" | "csv" | "xlsx";
  }>({
    fromDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    )
      .toISOString()
      .split("T")?.[0],
    toDate: new Date().toISOString().split("T")?.[0],
    format: "xlsx",
  });

  const handleExport = async () => {
    setLoadingExport(true);
    try {
      const data = await exportStudents({ ...form });
      console.log(data);
      if (form.format == "csv" || form.format == "xlsx") {
        const deflatedData = data.map((s) => deflate(s));
        const sameKeysData = ensureSameKeys(deflatedData);
        let sortableColumns: any[] = [];
        let suggestedCourcesColumns: any[] = [];

        //divide columns into sortable and suggestedCources
        //we want to put suggested cources at the end
        Object.keys(sameKeysData[0]).forEach((key) => {
          if (key.toLowerCase().includes("suggested")) {
            suggestedCourcesColumns.push(key);
          } else {
            sortableColumns.push(key);
          }
        });
        const sortedColumnsOrder = [
          ...sortableColumns.sort(),
          ...suggestedCourcesColumns.sort(
            (a: string, b: string) => (a[0] == b[0] ? 0 : a[0] > b[0] ? 1 : -1) //sort by first letter
          ),
        ];

        let reorderedData = sameKeysData.map((row) => {
          let newRow: any = {};
          sortedColumnsOrder.forEach((column) => {
            newRow[column] = row[column] || ""; // Preserve the column order
          });
          return newRow;
        });

        const worksheet = XLSX.utils.json_to_sheet(reorderedData);

        if (form.format == "csv") {
          const csv = XLSX.utils.sheet_to_csv(worksheet);
          const blob = new Blob([csv], {
            type: "text/csv;charset=utf-8;",
          });
          handleDownloadBlob(blob, "csv");
        } else if (form.format == "xlsx") {
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Assessments");

          XLSX.writeFile(
            workbook,
            `assessments_${form.fromDate}-${form.toDate}.xlsx`
          );
        }
      } else {
        //json
        const str = JSON.stringify(data);
        const bytes = new TextEncoder().encode(str);
        const blob = new Blob([bytes], {
          type: "application/json;charset=utf-8",
        });
        handleDownloadBlob(blob, "json");
      }
    } catch (err) {
      console.error(err);
    }
    setLoadingExport(false);
  };

  const handleDownloadBlob = (blob: Blob, format: string = "json") => {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `assessments_${form.fromDate}-${form.toDate}.${format}`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // const handleStopExport = () => {
  //   setStoppingExport(true);
  //   stopExport.current = true;
  // };

  // React.useEffect(() => {
  //   setStoppingExport(false);
  // }, [loadingExport]);

  return (
    <>
      <div className="width-create-Major rounded-lg p-16 my-16 bg-white shadow-sm position-relative">
        <Form className="d-flex flex-wrap">
          <div className="text-18 mb-24 font-weight-bold w-100">
            {t("exportInfo")}
          </div>
          <FormInput
            id="fromDate"
            label={t("fromDate")}
            value={form.fromDate}
            onChange={(e: any) =>
              setForm((p) => ({ ...p, fromDate: e.target.value }))
            }
            type="date"
            required
            width="50%"
            className="pr-8"
          />
          <FormInput
            id="toDate"
            value={form.toDate}
            onChange={(e: any) =>
              setForm((p) => ({ ...p, toDate: e.target.value }))
            }
            type="date"
            label={t("toDate")}
            required
            width="50%"
          />
          <FormInput
            label={t("format")}
            as="select"
            required
            value={form.format}
            onChange={(e: any) =>
              setForm((f) => ({ ...f, format: e.target.value }))
            }
            options={["xlsx", "json", "csv"]}
            onChooseOption={(option) =>
              setForm((f) => ({ ...f, format: option as any }))
            }
          />
          <div className="w-100 text-muted">
            <Form.Label>{t("exportUseFilters")}</Form.Label>
          </div>
          {loadingExport ? (
            <div className="d-flex w-100">
              {/* <ProgressBar
                className="w-100 mr-16 my-8"
                animated
                striped
                now={Math.ceil(exportProgress)}
              /> */}
              {/* <Button variant="danger" onClick={handleStopExport}>
                <Loader spinnerClassName="m-1 mx-14" loading={stoppingExport}>
                  {t("Stop")}
                </Loader>
              </Button> */}
            </div>
          ) : (
            <div
              className="cursor-pointer bg-transparent border-05 rounded border-gray-400 hover-blue no-after flex-center-between pr-8"
              onClick={() => handleExport()}
            >
              <ExportIcon className="m-4" />
              {t("Export")}
            </div>
          )}
        </Form>
        {onClose && (
          <Button
            variant="outline-secondary"
            className="position-absolute"
            style={{ top: 4, right: 4 }}
            onClick={() => onClose()}
          >
            <XIcon />
          </Button>
        )}
      </div>
    </>
  );
};

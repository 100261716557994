import React from "react";
import qs from "qs";

import malePic from "../assets/userpicMale.svg";
import femalePic from "../assets/userpicFemale.svg";

import { useStudentsApi } from "../hooks/useStudentsApi";
import { useLang } from "./lang";
import { useSearch } from "./search";
import { assessmentToStudent } from "../parsers";
import { AnyArn } from "aws-sdk/clients/groundstation";
import { AppStudent } from "../types";
import { useAuth } from "./authBasic";
import { useAuthenticator } from "@aws-amplify/ui-react";

const StudentsContext = React.createContext({
  data: {
    items: [],
  },
  loading: false,
  sortBy: "",
  setSortBy: () => {},
  filters: {
    status: [],
    language: [],
    mode: [],
    degree: [],
    dominantPersonality: [],
    languages: [], //old dynamo db functionality
  },
  applyFiltersAndSearch: (items: any[], filters: any) => [],
  setFilters: () => {},
  loadStudent: () => {},
  loadStudents: () => {},
  hasMore: false,
  nextPageNumberRef: { current: 0 },
  clear: () => {},
  loadMoreStudents: () => {},
  updateStudent: () => {},
  student: null,
  rejectTemplateOptions: () => {},
  deleteStudent: () => {},
  search: "",
});

const STUDENTS_PAGE_SIZE = 50;

const INIT_DATA: any = {
  default: {
    // sortByOptions: ["salary", "name"],
    statusOptions: [
      "new",
      "contacted",
      "applied",
      "accepted",
      "inProgress",
      "notInterested",
      "student",
    ],
    languageOptions: ["en", "pl"],
    modeOptions: ["fulltime", "parttime", "online"],
    degreeOptions: ["master", "bachelor", "postgrad"],
    dominantPersonalityOptions: [
      "r_score",
      "i_score",
      "a_score",
      "s_score",
      "e_score",
      "c_score",
    ],
    items: [],
  },
  leiden: {
    // sortByOptions: ["salary", "name"],
    statusOptions: [
      "new",
      "contacted",
      "applied",
      "accepted",
      "inProgress",
      "notInterested",
      "student",
    ],
    languageOptions: ["en", "nl"],
    modeOptions: ["fulltime", "parttime"],
    degreeOptions: ["master", "bachelor"],
    dominantPersonalityOptions: [
      "r_score",
      "i_score",
      "a_score",
      "s_score",
      "e_score",
      "c_score",
    ],
    items: [],
  },
};

const StudentsProvider = (props: any) => {
  const { t } = useLang();
  const { search } = useSearch();

  //user data
  const { user } = useAuthenticator();
  const userSession = user?.getSignInUserSession();
  const universityAcronym =
    userSession?.getAccessToken().payload["cognito:groups"][0];

  const studentsApi = useStudentsApi();

  const [loading, setLoading] = React.useState(false);

  const nextPageNumberRef = React.useRef<number>(0);

  const clear = () => {
    nextPageNumberRef.current = 0;
  };

  //prevent trigering extra loading
  const loadingRef = React.useRef<boolean>(false);

  const [data, setData] = React.useState<any>(
    INIT_DATA[universityAcronym || "default"] || INIT_DATA.default
  );

  const [filters, setFilters] = React.useState({
    status: [],
    language: [],
    mode: [],
    degree: [],
    dominantPersonality: [],
  });
  const [sortBy, setSortBy] = React.useState("salary");

  const [student, setStudent] = React.useState(null);

  const [rejectTemplateOptions, setRejectTemplateOptions] = React.useState([
    { key: 0, value: "test", message: "testmessage" },
    { key: 1, value: "test2", message: "testmessage2" },
  ]);

  // React.useEffect(() => {
  //   if (((Object.values(filters) as any) || []).some((v: any) => v.length)) {
  //     loadStudents();
  //   }
  // }, [filters]);

  const loadStudent = async (id: any) => {
    const {
      data: { data },
    } = await studentsApi.get(
      `/api/assessment/find-for-university?assessmentId=${id}`
    );
    if (data[0]) {
      const newStudent: any = assessmentToStudent(data[0]);

      setStudent(newStudent);
    }
  };

  const loadStudents = async () => {
    console.log("running load students");
    setLoading(true);

    const query = qs.stringify({
      //filters: buildQueryFilters(),
      pagination: {
        pageSize: STUDENTS_PAGE_SIZE,
      },
    });
    const {
      data: { data, meta },
    } = await studentsApi.get(`/api/assessment/find-for-university?${query}`);

    if (meta.pagination.pageCount > meta.pagination.page) {
      nextPageNumberRef.current = meta.pagination.page + 1;
    } else {
      nextPageNumberRef.current = 0;
    }

    setData((prev: any) => ({
      ...prev,
      items: data.map(assessmentToStudent),
    }));

    setLoading(false);
  };

  const loadMoreStudents = async () => {
    if (nextPageNumberRef.current && !loadingRef.current) {
      loadingRef.current = true;
      const {
        data: { data, meta },
      } = await studentsApi.get(
        `/api/assessment/find-for-university?pagination[pageSize]=${STUDENTS_PAGE_SIZE}&pagination[page]=${nextPageNumberRef.current}`
      );

      if (meta.pagination.pageCount > meta.pagination.page) {
        nextPageNumberRef.current = meta.pagination.page + 1;
      } else {
        nextPageNumberRef.current = 0;
      }

      const parsedResult = data.map(assessmentToStudent);

      setData(
        (prev: any) =>
          ({
            ...prev,
            items: [...prev.items, ...parsedResult],
          } as any)
      );

      loadingRef.current = false;
    }
  };

  const updateStudent = async (id: any, newData: any) => {
    await studentsApi.put(`api/assessments/${id}`, { data: newData });
  };

  const deleteStudent = async (id: any) => {
    await studentsApi.delete(`api/assessments/${id}/`);
  };

  const applyFiltersAndSearch = (
    items: any[],
    _filters: typeof filters = filters
  ) =>
    items
      .filter((item: any) =>
        Object.entries(_filters)
          .map(([key, value]) => {
            if (value.length == 0) {
              return true; //empty filter, everything passes
            } else {
              return value.every((filter) =>
                item[key]?.toLowerCase().includes(String(filter).toLowerCase())
              );
            }
          })
          .every((v) => v)
      )
      .filter((item: any) => {
        if (!search) {
          return true;
        } else {
          return `${t(item.name)} ${t(item.mode)} ${t(item.degree)}`
            .toLowerCase()
            .includes(search.toLowerCase());
        }
      });

  return (
    <StudentsContext.Provider
      value={{
        data: {
          ...data,
          items: applyFiltersAndSearch(data.items),
        },
        applyFiltersAndSearch,
        loading,
        sortBy,
        setSortBy,
        filters,
        setFilters,
        loadStudent,
        loadStudents,
        hasMore: Boolean(nextPageNumberRef.current),
        nextPageNumberRef,
        clear,
        loadMoreStudents,
        updateStudent,
        setStudent,
        student,
        rejectTemplateOptions,
        deleteStudent,
        search,
      }}
      {...props}
    />
  );
};

export const useStudents = () => React.useContext(StudentsContext);

export default StudentsProvider;

import React from "react";
import { ThumbsupIcon, StarIcon, ThumbsdownIcon } from "@primer/octicons-react";
import { useLang } from "../context/lang";

export default ({ items, onClear }) => {
  const { t } = useLang();

  return items.length > 0 ? (
    <div className="height-header w-100 bg-white shadow-sm flex-center-between px-20 mt-40">
      <div className="d-flex text-gray-500 text-16">
        <div
          style={{
            width: `${items.length.toString().length * 14}px`
          }}
        >
          {items.length}
        </div>
        {t("itemsSelected")}
      </div>
      <div className="d-flex">
        <div className="hover-elevate-200 rounded-pill cursor-pointer px-8">
          <ThumbsupIcon size={17} />
          <span className="text-16 ml-8">{t("invite")}</span>
        </div>
        <div className="ml-64 hover-elevate-200 rounded-pill cursor-pointer px-8">
          <StarIcon size={17} />
          <span className="text-16 ml-8">{t("shortlist")}</span>
        </div>
        <div className="ml-64 hover-elevate-200 rounded-pill cursor-pointer px-8">
          <ThumbsdownIcon size={17} />
          <span className="text-16 ml-8">{t("reject")}</span>
        </div>
      </div>
      <span
        className="text-danger text-14 font-weight-normal ml-16 cursor-pointer hover-underline"
        onClick={onClear}
      >
        {t("clearAll")}
      </span>
    </div>
  ) : null;
};

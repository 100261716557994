/** Legacy soluttion for authentification
 * @deprecated
 * Using aws amplify + Cognito auth instead
 */
import React from "react";
import { useHistory } from "react-router-dom";
import manAvatar from "../assets/man.png";
import createPersistedState from "use-persisted-state";

import { useStudentsApi } from "../hooks/useStudentsApi";
import { useLang } from "./lang";
import { useModal } from "./modal";

const useUserState = createPersistedState("user");

const AuthContext = React.createContext({
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
  loading: false,
  notifications: [],
  authError: "",
  user: {
    id: "",
    firstName: "",
    lastName: "",
    university: "",
    universityId: "",
    universityExternalId: "",
    avatarUrl: "",
  },
  // sendPasswordRecoverLink,
  // updateProfile,
});

function AuthProvider(props: any): any {
  const [user, setUser] = useUserState({
    id: "",
    firstName: "",
    lastName: "",
    university: "",
    universityId: "",
    universityExternalId: "",
    avatarUrl: "",
  });

  const [notifications, setNotifications] = React.useState([
    // [
    //   { name: "Pavlo Razinkin", time: "Today", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "Today", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "Today", avatarUrl: manAvatar }
    // ],
    // [
    //   { name: "Pavlo Razinkin", time: "12 days ago", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "12 days ago", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "12 days ago", avatarUrl: manAvatar }
    // ],
    // [
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar }
    // ],
    // [
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar }
    // ],
    // [
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar },
    //   { name: "Pavlo Razinkin", time: "1 month", avatarUrl: manAvatar }
    // ]
  ]);
  const [token, setToken] = React.useState(
    localStorage.getItem("tokenStudentsApi")
  );

  const studentsApi = useStudentsApi();
  const [authError, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { t } = useLang();
  const { openModal } = useModal();
  const history = useHistory();

  const isAuthenticated = Boolean(token);

  // React.useEffect(() => {
  //   // if (isAuthenticated) {
  //   //   getProfile();
  //   // }
  // }, []);

  // const getProfile = async () => {
  //   try {
  //     const { data } = await api.get("universities/profile/");
  //     console.log(data);
  //     setProfile(data);
  //   } catch (err) {
  //     console.error(err.response);
  //   }
  // };

  const setProfile = (data: any) => {
    setUser({
      id: "",
      firstName: data?.firstName,
      lastName: data?.lastName,
      university: data?.universityAcronym,
      universityId: "",
      universityExternalId: data.universityAcronym,
      avatarUrl: manAvatar, //TODO
    });
  };

  const login = async (username: any, password: any) => {
    try {
      const {
        data: { jwt, user },
      } = await studentsApi.post(
        "api/auth/local",
        {
          identifier: username,
          password,
        },
        { headers: { Authorization: undefined } } //make sure auth token is empty
      );
      localStorage.setItem("tokenStudentsApi", jwt);
      studentsApi.defaults.headers.common["Authorization"] = ` Bearer ${jwt}`;
      setToken(jwt);
      setProfile(user);
      history.push("/");
      window.location.reload();
    } catch (e: any) {
      console.error(e);
      console.log(e.response);
      if (e.response.status === 401) {
        setErrorMessage(t("wrongCredentials"));
      } else {
        setErrorMessage(
          e.response?.data?.error?.message ||
            e.response?.data.non_field_errors?.[0] ||
            e.message ||
            t("defaultErrorMessage")
        );
      }
    }
  };

  const sendPasswordRecoverLink = () => {
    openModal(t("checkYourEmail"), t("emailRecoveryMessage"));
  };

  const logout = async () => {
    setUser({
      id: "",
      firstName: "",
      lastName: "",
      firms: [],
      avatarUrl: "",
    });
    delete studentsApi.defaults.headers.common["Authorization"];
    localStorage.clear();
    history.push("/login");
  };

  const updateProfile = async (user: any) => {
    console.log(user);
    //await api.put("universities/profile/", user);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        loading,
        notifications,
        authError,
        user,
        // sendPasswordRecoverLink,
        // updateProfile,
      }}
      {...props}
    />
  );
}

export const useAuth = () => React.useContext(AuthContext);

export default AuthProvider;

import React from "react";
import Button from "react-bootstrap/Button";
import { USER_POOL_SETTINGS } from "../config/userPools";
import { useAuthenticator, Authenticator } from "@aws-amplify/ui-react";
import { Amplify, Auth, I18n } from "aws-amplify";
import { Loader } from "./Loader";
import logoDark from "../assets/logoDark.png";
import ChangeLanguage from "./ChangeLanguage";
import { useLang } from "../context/lang";

//import { Amplify } from "aws-amplify";

// import { createProfile } from "@/hooks/useApi/students";
// import { useLang } from "@/context/lang";

// const Authenticator = dynamic(() => import("./Authenticator"), { ssr: false });

I18n.putVocabularies({
  en: {
    //hack to change default aws labels, TODO: create custom Authentificator
    Username: "Email",
    "Enter your Username": " ",
    "Enter your Password": " ",
    "Please confirm your Password": " ",
    "Password is hidden": " ",
    "Sign in": "Sign in",
    "Back to Sign In": "Back",
    "Confirm TOTP Code": "Two-factor authentication",
    Code: "Code",
    "Code *": "Please, enter a code from authentication app",
  },
  pl: {
    Username: "Email",
    "Enter your Username": " ",
    "Enter your Password": " ",
    "Please confirm your Password": " ",
    "Password is hidden": " ",
    "Sign in": "Sign in",
    "Back to Sign In": "Back",
    "Confirm TOTP Code": "Two-factor authentication",
    Code: "Code",
    "Code *": "Please, enter a code from authentication app",
  },
});

export interface IProtectedProps {
  /** Keys for user pool destination. By default it is common "studentspeak" user pool */
  awsUserPoolConfig?: typeof USER_POOL_SETTINGS;
  /** predefined GUID of user which is generated on assessment start. Needed to connect assesment with later student instance. */
  referenceId?: string | null;
}

export default function Protected({
  awsUserPoolConfig = USER_POOL_SETTINGS,
  referenceId,
  children,
}: React.PropsWithChildren<IProtectedProps>) {
  // React.useLayoutEffect(() => {
  //TODO: use it as effect
  Amplify.configure(awsUserPoolConfig);

  // }, []);

  const { user, authStatus } = useAuthenticator();
  console.log(user);

  // const handleSignUp = async (formData: any) => {
  //   // custom login process
  //   const signUpResult = await Auth.signUp({
  //     ...formData,
  //     autoSignIn: {
  //       enabled: true,
  //     },
  //   });

  //   if (signUpResult.userSub) {
  //     await createProfile({
  //       latestAssessmentReferenceId: referenceId || null,
  //       userExternalId: signUpResult.userSub,
  //       universityAcronym: getUserPoolById(awsUserPoolConfig.Auth.userPoolId),
  //       email: formData.username,
  //     });
  //   }

  //   return signUpResult;
  // };

  const handleSignIn = async (formData: any) => {
    return Auth.signIn(formData);
  };

  const formFields = {
    setupTOTP: {
      QR: {
        totpIssuer: "Studentspeak",
        totpUsername: "admin",
      },
    },
  };

  return authStatus == "authenticated" ? (
    <Loader loading={!Boolean(user)}>{children}</Loader>
  ) : (
    <LoginWrapper>
      <>
        {/* <button
          onClick={() => Auth.federatedSignIn({ provider: "Passage" as any })}
        >
          sign in federated
        </button> */}
        <Authenticator
          formFields={formFields}
          services={{
            // handleSignUp,
            handleSignIn,
          }}
          components={{
            SignIn: {
              Header: () => <></>,
              Footer: () => <></>,
            },
          }}
        />
      </>
    </LoginWrapper>
  );
}

const LoginWrapper = ({ children }: React.PropsWithChildren) => {
  const { t } = useLang();

  // const handleSubmit = () => {
  //   const submitButton = document.querySelectorAll("button[type=submit]");
  //   console.log(submitButton);
  // };

  return (
    <div className="login-wrapper flex-center flex-column">
      <div className="w-100 flex-center" style={{ height: "20vh" }}>
        <img className="m-32" width="200" src={logoDark} />
      </div>
      <div className="login-body">
        {children}
        <div className="d-flex position-relative">
          {/* <Button
          onClick={handleSubmit}
          variant="primary"
          style={{ minWidth: "115px" }}
        >
          {t("signIn")}
        </Button> */}
          <div className="form-language-button pt-16">
            <label className="amplify-label">{t("Language:")}</label>
            <ChangeLanguage
              hideIcon
              light
              onChange={(lang: string) => {
                window.location.reload(); //to apply I18n settings in lang context
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

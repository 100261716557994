import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";

import StatusPill from "./StatusPill";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ReactComponent as StarIcon } from "../assets/star.svg";
import { editMajor } from "react-router-dom";

import { GlobeIcon, PeopleIcon } from "@primer/octicons-react";
import { useLang } from "../context/lang";
import { useMajors } from "../context/majors";

export default ({ major, isChecked, onCheck, onClick }) => {
  const { t } = useLang();
  const { setForm } = useMajors();
  const history = useHistory();

  const onChoose = () => {
    setForm(major);
    history.push(`editMajor`);
  };

  return (
    <div
      id={`major-card-${major.id}`}
      onClick={e => {
        if (
          (e.target.id === `major-card-${major.id}` ||
            e.target.id === `major-card-header-${major.id}`) &&
          onCheck
        ) {
          onCheck();
        }
      }}
      className="size-major-card bg-white shadow-sm rounded-lg py-10 px-24 mt-32 d-flex flex-column justify-content-around"
    >
      <div id={`major-card-header-${major.id}`} className="flex-center-between">
        {onCheck && <Form.Check checked={isChecked} onChange={onCheck} />}
        {/* <div className="flex-center-between">
          <StatusPill value={major.status} />
        </div> */}
      </div>
      <div
        onClick={() => onChoose()}
        className="d-flex w-100 mt-8  hover-underline cursor-pointer rounded"
      >
        <div className="">
          <span className="font-weight-bold text-18">
            {major.majorSpecialization}
          </span>
          {/* <div className="text-gray-500 text-16">{major.date}</div> */}
        </div>
      </div>
      <div className="d-flex mt-16 ">
        <i class="bi bi-signpost text-18"></i>
        <span className="text-16 ml-10">{major.majorName}</span>
      </div>
      <div className="d-flex mt-16">
        <GlobeIcon className="mt-2" size={18} />
        <span className="text-16 ml-10">{t(major.language)}</span>
      </div>
      <div className="d-flex mt-16">
        <i class="bi bi-calendar2-week text-18"></i>
        <span className="text-16 ml-10">{t(major.mode)}</span>
      </div>
      <div className="d-flex mt-16">
        <i class="bi bi-layers-fill text-18"></i>
        <span className="text-16 ml-10">{t(major.degree)}</span>
      </div>
      <div className="flex-center-between justify-content-end mt-4">
        {/* <Button variant="outline-primary" className="py-1">{`${
          major.newStudents
        } ${t("new")}`}</Button> */}
        <div className="cursor-pointer hover-underline text-muted">
          <span>{`${t("studentsApplied")} : ${major.clicks}`}</span>
        </div>
      </div>
    </div>
  );
};

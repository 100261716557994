export default {
  en: {
    languageName: "English",
    students: "Students",
    settings: "Settings",
    logout: "Logout",
    login: "Login",
    signIn: "Sign in",
    search: "Search",
    password: "Password",
    notifications: "Notifications",
    allStudents: "All students",
    sortBy: "Sort by",
    universityName: "University name",
    skills: "Skills",
    MajorYouAppliedFor: "Major you applied for",
    activeFilters: "Active filters",
    clearAll: "Clear All",
    clear: "Clear",
    new: "New",
    invited: "Invited",
    rejected: "Rejected",
    itemsSelected: "items selected",
    invite: "Invite",
    shortlist: "Shortlist",
    reject: "Reject",
    student: "Student",
    addToShortlist: "Add to shortlist",
    education: "Education",
    workPreferences: "Work preferences",
    languages: "Languages",
    language: "Language",
    personalityTraits: "Personality traits",
    isTheMaximumScore: "is the maximum score",
    meaningOfTheTest: "Meaning of the test",
    toolsAndSkills: "Tools & Skills",
    advancedness: "Advancedness",
    workExperience: "Relevant work experience",
    less: "Less",
    more: "More",
    vacancies: "My Major vacancies",
    department: "Department",
    status: "Status",
    new: "New",
    applied: "Applied",
    draft: "Draft",
    closed: "Closed",
    published: "Published",
    reverse: "Reverse",
    rejectStudent: "Reject student",
    chooseTemplate: "Choose template",
    title: "Title",
    email: "Email",
    message: "Message",
    close: "Close",
    open: "Open",
    save: "Save",
    sendMessage: "Send message",
    optional: "Optional",
    cc: "CC",
    fieldRequired: "This field is required",
    MajorParameters: "Major parameters",
    positionName: " Position name",
    firmName: "Firm name",
    city: "City",
    department: "Department",
    salary: "Salary (in pln)",
    perYear: "Per Year",
    perMonth: "Per Month",
    brutto: "Brutto",
    netto: "Netto",
    workType: "Work type",
    fullTime: "Full-time",
    partTime: "Part-time",
    fulltime: "Full-time",
    parttime: "Part-time",
    office: "Office",
    remote: "Remote",
    dontShowSalary: "Don't show salary",
    MajorDescription: "Major description",
    aboutUs: "About us",
    description: "Description",
    weOffer: "We offer",
    studentRequirements: "Student requirements",
    addLanguage: "Add language",
    skillsAndTech: "Skills & Tech",
    enterSkill: "Enter skill",
    requirements: "Requirements",
    post: "Post",
    enterCity: "Enter city",
    helpSkills: "Enter required skills and/or tools",
    publish: "Publish",
    delete: "Delete",
    cancel: "Cancel",
    deleteConfirmation: "Are you sure you want to delete these items?",
    selected: "selected",
    undoAlert: "You want be able to undo it",
    clearConfirmation: "Are you sure you want to clear form?",
    wrongCredentials: "Login or password is incorrect",
    defaultErrorMessage: "There is an error occurred... Please try later.",
    personalInfo: "Personal info",
    firstName: "First name",
    lastName: "Last name",
    companyName: "Company name",
    recoverPassword: "Recover password",
    recoverPasswordMessage:
      "Please tell us your e-mail so we can send you a recovery link if your account exists",
    yourEmail: "Your e-mail",
    emailMeRecover: "E-mail me a recovery link",
    autoNegative: "Automatic Negative Feedback",
    autoNegativeMessage:
      "Student recieves this automatic negative feedback in a set amount of days if you don’t do some actions with him during this time",
    amountOfDays: "Amount of days",
    amountOfMonth: "Amount of months",
    saveChanges: "Save changes",
    autoShortlist: "Automatic Shortlist Feedback",
    autoShortlistMessage:
      "Student recieves this automatic shortlist feedback in a set amount of days if you added student to favourites and don’t invited or rejected him during this time",
    periodOfEmployment: "Period of employment",
    organization: "Organization",
    role: "Role",
    whatDo: "What does the organization do?",
    whatRole: "Whats your role in a team?",
    responsibilities: "Responsibilities",
    tools: "Tools",
    keyAchievment: "Key achievment",
    proudOf: "What are you proud of",
    yearsOld: "years old",
    checkYourEmail: "Check your e-mail 💌",
    emailRecoveryMessage:
      "If your account exists, an email will be sent with further instructions. Please click the link when you get it to recover your password",
    waitingForFeedback: "is waiting for your feedback",
    markAsRead: "Mark all as read",
    contacted: "Contacted",
    applied: "Applied",
    accepted: "Accepted",
    inProgress: "In progress",
    notInterested: "Not interested",
    r_score: "Realistic",
    i_score: "Investigative",
    a_score: "Artistic",
    s_score: "Social",
    e_score: "Enterprising",
    c_score: "Conventional",
    sendEmail: "Send email",
    clearData: "Clear data",
    information: "Information",
    preferences: "preferences",
    sex: "Sex",
    widgetLanguage: "widgetLanguage",
    city: "City",
    mode: "Mode",
    degree: "Degree",
    fit: "Fit",
    male: "Pan",
    female: "Pani",
    online: "Online",
    dominantPersonality: "Personality",
    PL: "Polish",
    EN: "English",
    "PL,EN": "Polski, Angielski",
    "EN,PL": "Angielski, Polski",
    fulltime: "Full time",
    parttime: "Part time",
    "fulltime,parttime": "Full-time, part-time",
    "parttime,fulltime": "Part-time, full-time",
    master: "Master",
    bachelor: "Bachelor",
    postgrad: "Postgraduate",
    major: "Major",
    majors: "Majors",
    createMajor: "Create major",
    editMajor: "Edit major",
    majorInfo: "Major info",
    majorName: "Major name",
    majorSpecialization: "Major specialization",
    priceEuro: "Annual price for EU citizens 🇪🇺🇵🇱",
    priceNonEuro: "Annual price for not EU citizens 🇺🇦🇧🇾🇹🇷",
    link: "Major link",
    changePassword: "Change password",
    newPassword: "New password",
    oldPassword: "Old password",
    repeatNewPassword: "Repeat password",
    oldPassWrong: "Old password is wrong",
    differentPasswords: "Passwords don't match",
    changeLanguage: "Change language",
    pl: "Polish 🇵🇱",
    en: "English 🇺🇸",
    nl: "Dutch 🇳🇱",
    Export: "Export",
    exportInfo: "Export students",
    fromDate: "From date",
    toDate: "To date",
    exportUseFilters: "Choosed filters will be applied to export.",
    noNotifications: "No notifications yet",
    widgetLanguage: "Widget language",
    clicked: "Clicked",
  },
  pl: {
    languageName: "Polish",
    students: "Studenci",
    Majors: "Kierunki",
    settings: "Ustawienia",
    logout: "Wyłoguj się",
    login: "Nazwa użytkownika",
    signIn: "Załoguj się",
    search: "Szukaj",
    password: "Hasło",
    notifications: "Powiadomienia",
    allStudents: "Wszystkie studenci",
    sortBy: "Sortuj",
    universityName: "University name",
    skills: "Skills",
    MajorYouAppliedFor: "Major you applied for",
    activeFilters: "Aktywne filtry",
    clearAll: "Wyczyść wszystko",
    clear: "Wyczyść",
    invited: "Invited",
    rejected: "Rejected",
    itemsSelected: "items selected",
    invite: "Invite",
    shortlist: "Shortlist",
    reject: "Reject",
    student: "Student",
    addToShortlist: "Add to shortlist",
    education: "Education",
    workPreferences: "Work preferences",
    languages: "Języki",
    language: "Język",
    personalityTraits: "Personality traits",
    isTheMaximumScore: "jest wynikiem maksymalnym",
    meaningOfTheTest: "Interprytacja testu",
    toolsAndSkills: "Tools & Skills",
    advancedness: "Advancedness",
    workExperience: "Relevant work experience",
    less: "Less",
    more: "More",
    vacancies: "Kierunki i specjalności",
    department: "Department",
    status: "Status",
    new: "Nowy",
    draft: "Draft",
    closed: "Closed",
    published: "Published",
    reverse: "Reverse",
    rejectStudent: "Reject student",
    chooseTemplate: "Choose template",
    title: "Title",
    email: "Email",
    message: "Message",
    close: "Close",
    open: "Open",
    save: "Save",
    sendMessage: "Send message",
    optional: "Optional",
    cc: "CC",
    fieldRequired: "Wymagane pole",
    MajorParameters: "Major parameters",
    positionName: " Position name",
    firmName: "Firm name",
    city: "City",
    department: "Department",
    salary: "Salary (in pln)",
    perYear: "Per Year",
    perMonth: "Per Month",
    brutto: "Brutto",
    netto: "Netto",
    workType: "Work type",
    fullTime: "Full-time",
    partTime: "Part-time",
    office: "Office",
    remote: "Remote",
    dontShowSalary: "Don't show salary",
    MajorDescription: "Major description",
    aboutUs: "About us",
    description: "Description",
    weOffer: "We offer",
    studentRequirements: "Student requirements",
    addLanguage: "Add language",
    skillsAndTech: "Skills & Tech",
    enterSkill: "Enter skill",
    requirements: "Requirements",
    post: "Post",
    enterCity: "Enter city",
    helpSkills: "Enter required skills and/or tools",
    publish: "Publish",
    delete: "Delete",
    cancel: "Cancel",
    deleteConfirmation: "Are you sure you want to delete these items?",
    selected: "selected",
    undoAlert: "You want be able to undo it",
    clearConfirmation: "Are you sure you want to clear form?",
    wrongCredentials: "Login or password is incorrect",
    defaultErrorMessage: "There is an error occurred... Please try later.",

    companyName: "Company name",
    recoverPassword: "Recover password",
    recoverPasswordMessage:
      "Please tell us your e-mail so we can send you a recovery link if your account exists",
    yourEmail: "Your e-mail",
    emailMeRecover: "E-mail me a recovery link",
    autoNegative: "Automatic Negative Feedback",
    autoNegativeMessage:
      "Student recieves this automatic negative feedback in a set amount of days if you don’t do some actions with him during this time",
    amountOfDays: "Amount of days",
    amountOfMonth: "Amount of months",
    saveChanges: "Zapisz zmiany",
    autoShortlist: "Automatic Shortlist Feedback",
    autoShortlistMessage:
      "Student recieves this automatic shortlist feedback in a set amount of days if you added student to favourites and don’t invited or rejected him during this time",
    periodOfEmployment: "Period of employment",
    organization: "Organization",
    role: "Role",
    whatDo: "What does the organization do?",
    whatRole: "Whats your role in a team?",
    responsibilities: "Responsibilities",
    tools: "Tools",
    keyAchievment: "Key achievment",
    proudOf: "What are you proud of",
    yearsOld: "years old",
    checkYourEmail: "Check your e-mail 💌",
    personalInfo: "Dane osobowe",
    firstName: "Imie",
    lastName: "Nazwisko",
    emailRecoveryMessage:
      "Jeżeli konto istnieje, wyslemy instrukcje na podany adres email. Kliknij w link.",
    waitingForFeedback: "is waiting for your feedback",
    markAsRead: "Zaznać wszystko jako przeczytane",
    contacted: "Skontaktowano się",
    applied: "Złożył dokumenty",
    studentsApplied: "Zaaplikowano",
    accepted: "Skontaktować się póżniej",
    inProgress: "W trakcie aplikacji",
    notInterested: "Nie zainteresowany",
    r_score: "Realistyczny",
    i_score: "Badawczy",
    a_score: "Artystyczy",
    s_score: "Społeczny",
    e_score: "Przedsiębiorczy",
    c_score: "Konwencjonalny",
    sendEmail: "Wyślij email",
    clearData: "Wykasuj dane osobowe",
    information: "Informacja",
    preferences: "Preferencje",
    sex: "Zwrot grzecznościowy",
    widgetLanguage: "Język strony",
    city: "Miasto",
    mode: "Tryb studiów",
    degree: "Poziom studiów",
    fit: "Dopasowanie",
    male: "Pan",
    female: "Pani",
    dominantPersonality: "Osobowość",
    PL: "Polski",
    EN: "Angielski",
    "PL,EN": "Polski, Angielski",
    "EN,PL": "Angielski, Polski",
    fulltime: "Stacjonarne",
    parttime: "Zaoczne",
    postgrad: "Podyplomowy",
    online: "Online",
    "fulltime,parttime": "Stacjonarne, zaoczne",
    "parttime,fulltime": "Zaoczne, stacjonarne",
    master: "Magister",
    bachelor: "Licencjat",
    major: "Kierunek",
    majors: "Kierunki",
    createMajor: "Nowa specjalność",
    editMajor: "Edytuj specjalność",
    majorInfo: "Informacje o specialności",
    majorName: "Nazwa kierunku",
    majorSpecialization: "Nazwa specjalności",
    priceEuro: "Roczna cena za studia dla obywateli UE 🇪🇺🇵🇱",
    priceNonEuro: "Roczna cena za studia dla obywateli z poza UE 🇺🇦🇧🇾🇹🇷",
    link: "Link na specjalność lub kierunek",
    changePassword: "Zmień hasło",
    newPassword: "Nowe hasło",
    oldPassword: "Stare hasło",
    repeatNewPassword: "Powtórz nowe hasło",
    oldPassWrong: "Stare hasło jest niepoprawne",
    differentPasswords: "Hasła nie zgadzają się",
    changeLanguage: "Zmień język",
    pl: "Polski 🇵🇱",
    en: "Angielski 🇺🇸",
    nl: "Dutch 🇳🇱",
    Export: "Eksportuj",
    exportInfo: "Eksportuj studentów",
    fromDate: "Data od",
    toDate: "Data do",
    exportUseFilters: "Przy eksportowaniu bedą zastosowane wybrane filtry.",
    clicked: "Obejrzane",
  },
};

import React, { Component } from "react";
import Spinner from "react-bootstrap/Spinner";

export const Loader = ({ loading, children, spinnerClassName = "" }) =>
  loading ? (
    <div className="w-100 flex-center">
      <Spinner
        className={spinnerClassName ? spinnerClassName : `m-32`}
        animation="border"
        role="status"
        variant="secondary"
      />
    </div>
  ) : (
    <>{children}</>
  );

import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

//normal overlay trigger from BS only with close on click outside
export default props => {
  const wrapper = React.useRef();
  const [show, setShow] = React.useState(false);

  const onToggle = () => {
    setShow(prev => !prev);
  };

  React.useEffect(() => {
    if (props.trigger === "click") {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);

  const handleClickOutside = event => {
    if (wrapper.current && !wrapper.current.contains(event.target)) {
      setShow(false);
    }
  };

  return (
    <OverlayTrigger
      {...props}
      overlay={overlayProps => (
        <div ref={wrapper}>
          {React.cloneElement(props.overlay, overlayProps)}
        </div>
      )}
      show={show}
      onToggle={onToggle}
    />
  );
};

import React from "react";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";

import { useLang } from "../context/lang";

export default React.forwardRef(
  (
    {
      onSubmit,
      onClose,
      submitText,
      header,
      headerDetails,
      text,
      className,
      ref,
      ...other
    },
    forwardedRef
  ) => {
    const { t } = useLang();

    return (
      <Popover
        {...other}
        arrowProps={{ style: { display: "none" } }}
        id="popover-confirm"
        className={`${className} width-popover bg-dark rounded py-16 px-24 text-white`}
        ref={forwardedRef}
      >
        <Popover.Title className="bg-dark border-bottom-0 no-before" as="h4">
          {header}&nbsp;
          <span className="font-weight-normal">{headerDetails}</span>
        </Popover.Title>
        <Popover.Content className="bg-dark text-white">
          <div className="mt-8">{text}</div>
          <div className="mt-32 mb-12 d-flex">
            <Button
              variant="primary"
              className="rounded-lg"
              onClick={async () => {
                await onSubmit();
                onClose();
              }}
            >
              {submitText || t("delete")}
            </Button>
            <Button
              variant="secondary"
              className="text-primary rounded-lg ml-8"
              onClick={onClose}
            >
              {t("cancel")}
            </Button>
          </div>
        </Popover.Content>
      </Popover>
    );
  }
);

import React from "react";
import OverlayTrigger from "./OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";

import { ReactComponent as SearchIcon } from "../assets/search.svg";
import { BellIcon } from "@primer/octicons-react";
import { useLocation, useHistory, Link } from "react-router-dom";

import Notifications from "./Notifications";
import { useStudents } from "../context/students";
import { useLang } from "../context/lang";
import { useAuth } from "../context/authBasic";
import { useSearch } from "../context/search";

export default () => {
  const { t } = useLang();
  const { user, notifications } = useAuth();
  const { search, setSearch } = useSearch();
  const { pathname } = useLocation();
  return (
    <div className="w-100 height-header flex-center-between mx-auto mt-32 mb-20 mobile-flex-column-reverse mb-lg-10">
      {["/students", "/majors"].some((p) => p == pathname) ? (
        <div className="h-100 width-search flex-center-between bg-gray-300 rounded-lg position-relative ">
          <input
            className="w-100 h-100 pl-16 bg-transparent border-0 rounded-lg focus"
            placeholder={t("search")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          ></input>
          <div className="position-absolute right-16">
            {" "}
            <SearchIcon />
          </div>
        </div>
      ) : (
        <div className="width-search"></div>
      )}
      {/* <div className="flex-center-between mb-10">
        <OverlayTrigger
          trigger="click"
          placement="bottom-end"
          overlay={<Notifications />}
        >
          <div>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-bottom">{t("notifications")}</Tooltip>
              }
            >
              <div className="d-flex position-relative">
                <BellIcon
                  size={20}
                  className="hover-elevate-200 cursor-pointer"
                />
                {notifications.length > 0 && (
                  <div className=" flex-center bg-primary rounded-circle position-absolute left-16 bottom-16 size-notifications-icon text-white text-8">
                    {notifications.flat().length}
                  </div>
                )}
              </div>
            </OverlayTrigger>
          </div>
        </OverlayTrigger>
        <h6 className="ml-16 mb-0">{`${user.firstName} ${user.lastName}`}</h6>
        <div className="rounded-circle ml-16 border bg-gray-300">
          <img
            className="rounded-circle border border-light"
            src={user.avatarUrl}
            width="35"
          />
        </div>
      </div> */}
    </div>
  );
};

import React from "react";
import StudentsFilters from "../components/StudentsFilters";
import StudentCard from "../components/StudentCard";
import StudentsActions from "../components/StudentsActions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from "react-bootstrap/Fade";
import _ from "lodash";

import Export from "./Export";

import { ReactComponent as ExportIcon } from "../assets/export.svg";
import { Loader } from "../components/Loader";
import { useLang } from "../context/lang";
import { useStudents } from "../context/students";
import { useHistory } from "react-router-dom";
import { useSearch } from "../context/search";

export default () => {
  const { t } = useLang();
  const history = useHistory();
  const {
    data,
    loadStudents,
    loadMoreStudents,
    hasMore,
    filters,
    nextPageNumberRef,
    clear,
    loading,
  } = useStudents();

  const { search } = useSearch();

  React.useEffect(() => {
    //we want to refresh students list if filters or search is empty to avoid rendering a big list of students
    //after they have been loaded up in infinitive scroll
    //
    //also this is used for initial loading
    if (Object.values(filters).every((f) => f.length == 0) && search == "") {
      console.log("triggering load students");
      loadStudents();
    }
  }, [filters, search]);

  React.useEffect(() => {
    //check if page needs to load more students on changed fitlers
    if (Object.values(filters).some((f) => f.length)) {
      trackScrolling();
    }
  }, [filters]);

  const [checkedStudents, setCheckedStudents] = React.useState<any[]>([]);
  const [openExport, setOpenExport] = React.useState(false);

  const handleCheckStudent = (id: any) =>
    checkedStudents.includes(id)
      ? setCheckedStudents(checkedStudents.filter((v) => v !== id))
      : setCheckedStudents([...checkedStudents, id]);

  const handleChooseStudent = (id: any) => history.push(`/students/${id}`);

  /** Infinite scroll effect */
  React.useEffect(() => {
    if (window) {
      window.addEventListener("scroll", trackScrolling, true);
    }
    return () => {
      clear();
      if (window) {
        window.removeEventListener("scroll", trackScrolling);
      }
    };
  }, []);

  const loadingMore = React.useRef(false);

  const _trackScrolling = async (event?: any) => {
    if (window.location.pathname == "/students") {
      if (
        document.body.offsetHeight - (window.innerHeight + window.scrollY) <
          500 &&
        nextPageNumberRef.current
      ) {
        if (!loadingMore.current) {
          loadingMore.current = true;
          await loadMoreStudents();
          loadingMore.current = false;
          //fire once again to check if new items appeared and wasn't filtered by search
          trackScrolling();
        }
      }
      // else if (window.scrollY == 0 && !loadingMore.current && event) {
      //   if (nextPageNumberRef.current?.split("=")[1] !== "2") {
      //     //it is not the first page
      //     loadingMore.current = true;
      //     await loadStudents();
      //     loadingMore.current = false;
      //   }
      // }
    }
  };

  const trackScrolling = React.useCallback(
    _.debounce(_trackScrolling, 500),
    []
  );

  React.useEffect(() => {
    handleLoadLessStudents();
    trackScrolling();
  }, [search]);

  const handleLoadLessStudents = async () => {
    // if (
    //   nextPageNumberRef.current?.split("=")[1] !== "2" &&
    //   !loadingMore.current
    // ) {
    //   loadingMore.current = true;
    //   await loadStudents();
    //   loadingMore.current = false;
    //   trackScrolling();
    // }
  };

  return (
    <Loader loading={loading}>
      <div className="w-100">
        <div className="w-100 flex-center-between pt-8">
          <h2>{t("allStudents")}</h2>
          <div />
          {!openExport && (
            <div
              className="cursor-pointer bg-transparent border-05 rounded border-gray-400 hover-blue no-after flex-center-between pr-8"
              onClick={() => setOpenExport(true)}
            >
              <ExportIcon className="m-4" />
              {t("Export")}
            </div>
          )}
        </div>
        {openExport && (
          <Fade in={openExport}>
            <Export onClose={() => setOpenExport(false)} />
          </Fade>
        )}
        <StudentsFilters />
        <StudentsActions
          items={checkedStudents}
          onClear={() => setCheckedStudents([])}
        />
        <Row>
          {data.items.map((item: any) => (
            <Col
              sm={12}
              md={6}
              lg={4}
              key={item.external_id}
              className="px-15 px-md-0"
            >
              <StudentCard
                student={item}
                isChecked={checkedStudents.includes(item.id)}
                onCheck={() => handleCheckStudent(item.id)}
                onClick={() => handleChooseStudent(item.id)}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Loader loading={Boolean(nextPageNumberRef?.current)}>
            <></>
          </Loader>
        </Row>
      </div>
    </Loader>
  );
};

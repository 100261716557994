import React from "react";
import Form from "react-bootstrap/Form";
import moment from "moment";
import StatusPill from "./StatusPill";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ReactComponent as StarIcon } from "../assets/star.svg";
import {
  ClockIcon,
  MilestoneIcon,
  DatabaseIcon,
  MortarBoardIcon,
} from "@primer/octicons-react";
import { useLang } from "../context/lang";
import { useStudents } from "../context/students";

export default ({ student, isChecked, onCheck, onClick }) => {
  const { t } = useLang();
  const { data, updateStudent, loadStudents } = useStudents();
  return (
    <div
      id={`student-card-${student.id}`}
      // onClick={e => {
      //   if (
      //     e.target.id === `student-card-${student.id}` ||
      //     e.target.id === `student-card-header-${student.id}`
      //   ) {
      //     onCheck();
      //   }
      // }}
      className="size-student-card bg-white shadow-sm rounded-lg py-10 px-24 mt-32 mx-auto"
    >
      <div
        id={`student-card-header-${student.id}`}
        className="flex-center-between justify-content-between"
      >
        {/* <Form.Check className="cursor-pointer" checked={isChecked} onChange={onCheck} /> */}
        <div className="flex-center-between">
          <StatusPill
            chooseOptions={data.statusOptions}
            onChoose={async (status) => {
              await updateStudent(student.id, { status });
              loadStudents();
            }}
            value={student.status}
          />
          {student.shortList && (
            <div className="ml-16">
              <StarIcon />
            </div>
          )}
        </div>
      </div>
      <div
        onClick={onClick}
        className="d-flex w-100 mt-8  hover-underline cursor-pointer rounded"
      >
        <img src={student.avatarSrc} width="50" className="rounded-circle" />
        <div className="ml-16">
          <span className="font-weight-bold text-18 ">{student.name}</span>
          {student.submitDate && (
            <div className="text-gray-500 text-16">
              {moment(student.submitDate).format("DD-MM-yyyy HH:mm")}
            </div>
          )}
        </div>
      </div>
      <div className="d-flex mt-23">
        {student.degree && (
          <>
            <MortarBoardIcon className="mt-4" size={20} />
            <span className="text-16 ml-10 max-width-240">
              {t(student.degree)}
            </span>
          </>
        )}
      </div>
      <div className="d-flex mt-23 mb-23">
        {student.mode && (
          <>
            <ClockIcon className="mt-2" size={20} />
            <span className="text-16 ml-10 max-width-240">
              {t(student.mode)}
            </span>
          </>
        )}
      </div>
      {student.skills.map(({ key, value }) => (
        <div key={key} className="mt-8">
          <div className="flex-center-between w-100">
            <span className="text-gray-500 text-16">{t(key)}</span>
            <span className="text-16">{`${String(value * 100).slice(
              0,
              2
            )}%`}</span>
          </div>
          <ProgressBar className="height-progress" now={value * 100} />
        </div>
      ))}
    </div>
  );
};

import React from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FormInput from "./FormInput";

import { useLang } from "../context/lang";
import { useStudents } from "../context/students";

export default ({ isOpen, onClose, onSubmit }) => {
  const [validated, setValidated] = React.useState(false);
  const { t } = useLang();
  const { student, rejectTemplateOptions } = useStudents();
  const formRef = React.useRef(null);

  const [form, setForm] = React.useState({
    title: rejectTemplateOptions[0].title,
    email: student.email,
    message: rejectTemplateOptions[0].message
  });

  const handleChange = (key, value) =>
    setForm(prev => ({ ...prev, [key]: value }));

  const handleSubmit = event => {
    if (event.currentTarget.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // onSubmit();
    setValidated(true);
  };

  const handleChangeTemplate = value => {
    const newTemp = rejectTemplateOptions.find(({ key }) => key == value);
    setForm(prev => ({
      ...prev,
      title: newTemp.title,
      message: newTemp.message
    }));
  };

  return (
    <Modal show={isOpen} onHide={onClose} size="lg" dialogClassName="px-16">
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <div className="text-18 mt-16 ml-16 font-weight-bold">
          {t("rejectStudent")}
        </div>
        <Modal.Body>
          <FormInput
            label={t("chooseTemplate")}
            as="select"
            onChange={e => handleChangeTemplate(e.target.value)}
            options={rejectTemplateOptions}
            onChooseOption={option =>
              setForm({ title: option.title, message: option.message })
            }
          />
          <FormInput
            label={t("title")}
            value={form.title}
            onChange={e => handleChange("title", e.target.value)}
          />
          <FormInput
            label={t("cc")}
            value={form.email}
            onChange={e => handleChange("email", e.target.value)}
            type="email"
          />
          <FormInput
            label={`${t("message")}:`}
            as="textarea"
            required
            value={form.message}
            onChange={e => handleChange("message", e.target.value)}
            controlProps={{ rows: 5 }}
          />
        </Modal.Body>

        <Modal.Footer className="border-top-0">
          <Button
            variant="secondary"
            className="rounded-lg text-primary"
            onClick={onClose}
          >
            {t("close")}
          </Button>
          <Button variant="primary" className="rounded-lg" type="submit">
            {t("sendMessage")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

import React from "react";
import { useLang } from "../context/lang";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { QuestionIcon, EyeIcon, EyeClosedIcon } from "@primer/octicons-react";

interface IProps {
  id?: string;
  type?: string;
  as?: string;
  label: string;
  value?: any;
  onChange: (e: any) => void;
  controlId?: string;
  required?: boolean;
  width?: string;
  help?: string;
  className?: string;
  options?: any[];
  onChooseOption?: (option: string) => void;
  controlProps?: any;
  feedbackType?: any;
  feedbackMessage?: any;
  inputComponent?: any;
}

export default ({
  id,
  type = "input",
  as = undefined,
  label = " ",
  value,
  onChange,
  controlId,
  required = false,
  width = "100%",
  help,
  className = "",
  options,
  onChooseOption,
  controlProps,
  feedbackType,
  feedbackMessage,
  inputComponent,
}: IProps) => {
  const { t } = useLang();

  const [hide, setHide] = React.useState(type === "password" ? true : false);

  return (
    <Form.Group
      className={`${className} position-relative`}
      style={{ width }}
      controlId={id || controlId || label || undefined}
    >
      <div className="flex-center-between w-100 text-muted text-16">
        <Form.Label>{label}</Form.Label>
        {!required && (
          <Form.Label className="text-14">{t("optional")}</Form.Label>
        )}
        {help && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-help">{help}</Tooltip>}
          >
            <span>
              <QuestionIcon
                className="hover-elevate-200 cursor-pointer"
                size={18}
              />
            </span>
          </OverlayTrigger>
        )}
      </div>
      {inputComponent || (
        <Form.Control
          type={type === "password" ? (hide ? type : "text") : type}
          as={as}
          value={value}
          onChange={onChange}
          required={required}
          {...controlProps}
          className="border-gray-200"
        >
          {options && onChooseOption
            ? options.map((option) =>
                typeof option == "string" ? (
                  <option onClick={() => onChooseOption(option)} value={option}>
                    {option}
                  </option>
                ) : (
                  <option
                    onClick={() => onChooseOption(option)}
                    value={option.key}
                  >
                    {option.value}
                  </option>
                )
              )
            : null}
        </Form.Control>
      )}
      {type === "password" && (
        <div
          className="position-absolute"
          style={{ right: "18px", top: "38px" }}
          onClick={() => setHide((prev) => !prev)}
        >
          {hide ? <EyeIcon /> : <EyeClosedIcon />}
        </div>
      )}
      {(required || feedbackType) && (
        <Form.Control.Feedback type={feedbackType || "invalid"}>
          {feedbackMessage || t("fieldRequired")}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

import React from "react";
import NavLink from "./NavLink";
import { useLang } from "../context/lang";
import logoTop from "../assets/logoTop.svg";
import logoBottom from "../assets/logoBottom.svg";
import { ReactComponent as Person } from "../assets/person.svg";

import { ReactComponent as Exit } from "../assets/exit.svg";
import ChangeLanguage from "./ChangeLanguage";
import { useAuthenticator } from "@aws-amplify/ui-react";

export default () => {
  const { t, lang, setLang, getAvailableLangs } = useLang();
  const { user } = useAuthenticator();
  const [mobileOpened, setMobileOpened] = React.useState(false);

  const toogleNavbar = () => setMobileOpened((prev) => !prev);

  const logout = () => {
    user.signOut();
    window.location.reload();
  };

  return (
    <>
      <div className="hide-mobile">
        <div className="bg-dark size-menu position-fixed">
          <div className="w-100 flex-center">
            <img className="m-32" src={logoTop} />
          </div>
          <NavLink to="/students" icon={<Person />} text={t("students")} />
          {/* <NavLink to="/majors" icon={<Briefcase />} text={t("Majors")} />
          <NavLink to="/settings" icon={<Gear />} text={t("settings")} /> */}
          <NavLink
            onClick={() => logout()}
            icon={<Exit />}
            text={t("logout")}
          />
          <ChangeLanguage />
          <div className="w-100 bottom-0 position-absolute flex-center">
            <img className="m-32" src={logoBottom} />
          </div>
        </div>
        <div className="size-menu"></div>
      </div>
      <div className="d-none show-mobile">
        <div className="bg-dark">
          <div className="d-flex flex-center-between">
            <img className="m-16" src={logoTop} />

            <button
              className="navbar-toggler toggler-example"
              type="button"
              onClick={toogleNavbar}
            >
              <span className="text-white">
                <svg
                  style={{ width: "24px", height: "24px" }}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="white"
                    d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
                  />
                </svg>
              </span>
            </button>
          </div>
          {mobileOpened && (
            <div className="bg-light ">
              <NavLink to="/students" icon={<Person />} text={t("students")} />
              {/* <NavLink to="/majors" icon={<Briefcase />} text={t("Majors")} />
              <NavLink to="/settings" icon={<Gear />} text={t("settings")} /> */}
              <NavLink
                onClick={() => logout()}
                icon={<Exit />}
                text={t("logout")}
              />
            </div>
          )}
          <hr className="m-0" />
        </div>
      </div>
    </>
  );
};

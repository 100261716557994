import malePic from "../assets/userpicMale.svg";
import femalePic from "../assets/userpicFemale.svg";
import _ from "lodash";
import { ApiAssessmentAssessment } from "../types/studentspeakStudents";
import { ApiMajorMajor } from "../types/studentspeakUniversities";

export const assessmentToStudent = (item: ApiAssessmentAssessment) => {
  //console.log(item);

  const riasecScoresArr = item.attributes.riasecScores.split(",");

  const skillSet = {
    r_score: riasecScoresArr[0],
    i_score: riasecScoresArr[1],
    a_score: riasecScoresArr[2],
    s_score: riasecScoresArr[3],
    e_score: riasecScoresArr[4],
    c_score: riasecScoresArr[5],
  };

  const skillsArr = Object.entries(skillSet).map(([key, value]) => ({
    key,
    value, //connvert to typeform score format
  }));

  const parseStringOrArray = (item: any) => {
    if (item instanceof Array) {
      return item.join(",");
    } else {
      return item;
    }
  };
  const suggestedCources =
    item.attributes.suggestedCources || item.attributes.initialSuggestedCources;
  return {
    id: (item as any).id,
    last_name: (item.attributes as any).lastName,
    name: (item.attributes as any).name || "noname",
    email: (item.attributes as any).email,
    external_id: item.attributes.referenceId,
    mode: parseStringOrArray(item.attributes.studyMode),
    price: undefined,
    degree: item.attributes.degreeType,
    widgetLanguage: item.attributes.assessmentLanguage,
    is_already_student: item.attributes.alreadyStudent,
    phoneNo: undefined,
    newsletter_allowed: item.attributes.newsletterAllowed,
    acronym: item.attributes.hollandCode,
    s3_link: undefined,
    language: parseStringOrArray(item.attributes.language),
    currency: "PLN",
    sex: item.attributes.sex,
    is_euro: !Boolean(item.attributes.nonEu),
    city: undefined,
    submitDate: item.attributes.createdAt,
    university: item.attributes.universityAcronym,
    status: item.attributes.alreadyStudent ? "student" : item.attributes.status,
    salary: "TODO:money",
    age: "TODO:age",
    avatarSrc: item.attributes.sex === "male" ? malePic : femalePic,
    school: "TODO:wybrany kierunek",
    major: "TODO:wybrany kierunek",
    skills: skillsArr,
    dominantPersonality: skillsArr.sort((a, b) => a.value - b.value)[0].key,
    majors: suggestedCources?.map(
      (cource: ApiMajorMajor & { clicked: boolean }) => ({
        major_name: cource.attributes.name,
        specialization: cource.attributes.specialization,
        link: cource.attributes.link,
        mode: cource.attributes.studyMode,
        language: cource.attributes.language,
        fit: (cource.attributes as any).fitrate,
        clicked: cource.clicked,
      })
    ),
    maxScore: Math.max(...Object.values(skillSet)),
  };
};

/** for old django backend */
export const djangoStudentToStudent = (item: any) => {
  return {
    ...item,
    status: item.is_already_student ? "student" : item.status,
    salary: "TODO:money",
    name: item.name || "noname",
    age: "TODO:age",
    id: item.id,
    avatarSrc: item.sex === "male" ? malePic : femalePic,
    school: "TODO:wybrany kierunek",
    major: "TODO:wybrany kierunek",
    skills: Object.keys(item)
      .filter((k) => k.includes("_score"))
      .map((key) => ({ key, value: item[key] })),
    dominantPersonality: Object.keys(
      _.pickBy(item, (v, k) => k.includes("_score"))
    ).reduce((a, b) => (item[a] > item[b] ? a : b)),
    mode: item.mode, // todo
    submitDate: item.submit_date,
  };
};

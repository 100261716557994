import React from "react";

const SearchContext = React.createContext();

const SearchProvider = (props) => {
  //const storSearch = localStorage.getItem("userSearchuage");
  const [search, setSearch] = React.useState("");
  const searchRef = React.useRef("");

  React.useEffect(() => {
    searchRef.current = search;
  });

  return (
    <SearchContext.Provider
      value={{
        search,
        setSearch,
        searchRef,
      }}
      {...props}
    />
  );
};

export const useSearch = () => React.useContext(SearchContext);

export default SearchProvider;

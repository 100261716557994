import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { TriangleDownIcon } from "@primer/octicons-react";
import { useLang } from "../context/lang";
export default ({
  label,
  value,
  options,
  onChoose,
  toogleProps = {},
  ...props
}) => {
  const { t } = useLang();
  return (
    <Dropdown {...props} className={`mx-4 mb-3 ${props.className || ""}`}>
      <Dropdown.Toggle
        variant="outline-light"
        {...toogleProps}
        className={`width-filter bg-transparent border-05 border-gray-400 hover-blue no-after flex-center-between ${
          toogleProps.className || ""
        }`}
      >
        <span>
          <span className="text-muted">{label}</span>
          {value && <span className="text-dark"> {value}</span>}
        </span>
        <span className="text-dark d-flex">
          <TriangleDownIcon />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option, key) => (
          <Dropdown.Item key={key} onClick={() => onChoose(option)}>
            {t(option)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

import React from "react";
import { I18n } from "aws-amplify";

export type LangCode = "en" | "da" | "de" | "sv" | "nl";

type Translation = {
  [langCode in LangCode]?: {
    flagUrl: string;
    languageName: string;
    [key: string]: string;
  };
};

interface ILangContext {
  t: (key: string) => string;
  getAvailableLangs: () => {
    code: LangCode;
    title?: string;
    flagUrl?: string;
  }[];
  lang: LangCode;
  /** To properly change user lang in the app use context/user.switchLang */
  setLang: (lang: LangCode) => void;
  dateFnsLocale?: Object;
}

const LangContext = React.createContext<ILangContext>({} as ILangContext);

const LangProvider = ({ translation, ...props }: any) => {
  const storLang = localStorage.getItem("userLanguage") || "pl";
  const [lang, setLanguage] = React.useState(
    storLang && storLang !== "null" && translation[storLang] ? storLang : "pl"
  );

  React.useEffect(() => {
    I18n.setLanguage(lang);
  }, [lang]);

  const setLang = (lang: LangCode) => {
    setLanguage(lang);
    localStorage.setItem("userLanguage", lang);
  };

  const t = (key: string) => {
    return key
      ?.split(",")
      .map((k) => translation[lang][k] || k)
      .join(",");
  };

  const getAvailableLangs = () =>
    Object.keys(translation).map((langCode) => ({
      code: langCode,
      title: translation[langCode].languageName,
      flagUrl: translation[langCode].flagUrl,
    }));

  return (
    <LangContext.Provider
      value={{
        getAvailableLangs,
        lang,
        setLang,
        t,
      }}
      {...props}
    />
  );
};

export const useLang = () => React.useContext(LangContext);

export default LangProvider;

import React from "react";
import Modal from "react-bootstrap/Modal";

const ModalContext = React.createContext();

function ModalProvider(props) {
  const [modal, setModal] = React.useState({
    isOpen: false,
    type: "info",
    title: "",
    message: ""
  });

  const openModal = (title, message = "", type = "info") => {
    setModal({ isOpen: true, title, message, type });
  };

  const handleClose = event => {
    setModal({ isOpen: false });
  };

  return (
    <>
      <Modal
        show={modal.isOpen}
        size="lg"
        className="border-0"
        onHide={handleClose}
      >
        <Modal.Header className="border-0 pb-0" closeButton>
          <Modal.Title className="text-18">{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-muted pt-10">{modal.message}</Modal.Body>
      </Modal>
      <ModalContext.Provider
        value={{
          openModal
        }}
        {...props}
      />
    </>
  );
}

export const useModal = () => React.useContext(ModalContext);

export default ModalProvider;

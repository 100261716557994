import React from "react";
import MajorsFilters from "../components/MajorsFilters";
import MajorCard from "../components/MajorCard";
import MajorsActions from "../components/MajorsActions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { PlusIcon } from "@primer/octicons-react";
import { Loader } from "../components/Loader";
import { useLang } from "../context/lang";
import { useMajors } from "../context/majors";
import { useHistory } from "react-router-dom";

export default () => {
  const { t } = useLang();
  const history = useHistory();
  const {
    data: { items },
    filters,
    loadMajors,
    setForm,
    initForm,
    loading,
  } = useMajors();

  const [checked, setChecked] = React.useState([]);

  React.useState(() => {
    loadMajors();
  }, []);

  // const handleCheck = id =>
  //   checked.includes(id)
  //     ? setChecked(checked.filter(v => v !== id))
  //     : setChecked([...checked, id]);

  const handleChooseMajor = (id) => history.push(`/majors/${id}`);

  return (
    <Loader loading={loading}>
      <div className="w-100">
        <div className="w-100 flex-center-between">
          <h2>{t("majors")}</h2>
          <Button
            className="rounded-lg d-flex py-12"
            variant="primary"
            onClick={() => {
              setForm(initForm);
              history.push("/createMajor");
            }}
          >
            <PlusIcon />{" "}
          </Button>
        </div>
        <MajorsFilters />
        <MajorsActions items={checked} onClear={() => setChecked([])} />
        <Row>
          {items.map((item) => (
            <Col sm={12} md={6} lg={4} key={item.id}>
              <MajorCard
                major={item}
                //isChecked={checked.includes(item.id)}
                //onCheck={() => handleCheck(item.id)}
                onClick={() => handleChooseMajor(item.id)}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Loader>
  );
};
